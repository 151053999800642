import React from "react"
import Layout from "../layout/Layout";
import Container from "../layout/Container";

const ImpressumPage = () => (
    <div className="sk-Impressum">
        <Layout pageTitle="Impressum">
            <Container>
                <h1>Impressum</h1>
                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>
                    <strong>
                        Schreinerei Kraiser
                        <br />Steffen & Martin Kraiser GbR
                    </strong>
                    <br /> Morglachstraße 3
                    <br /> 72660 Beuren
                    <br />
                    <br /> Telefon: 0 70 25 / 23 25
                    <br /> Fax: 0 70 25 / 45 33
                    <br /> E-Mail: <a href="mailto:info@schreinerei-kraiser.de">info@schreinerei-kraiser.de</a>
                </p>

                <p>
                    <br /><strong>Vertretungsberechtigte Gesellschafter:</strong>
                    <br /> Steffen Kraiser und Martin Kraiser
                    <br /><strong>Ust.SteuerID.:</strong> 74037/32343
                </p>

                <h2>Online-Streitbeilegung</h2>
                <p>Die Europ&auml;ische Kommission stellt unter <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a> eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher f&uuml;r die Beilegung einer Streitigkeit nutzen k&ouml;nnen und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.</p>

                <h2>Au&szlig;ergerichtliche Streitbeilegung</h2>
                <p>Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                <h2>Symbole aus folgenden Quellen</h2>
                <a href="https://www.freepik.com/" target="_blank" rel="noopener noreferrer" title="Freepik">Freepik</a> von <a href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer" title="Flaticon">www.flaticon.com</a> lizensiert durch <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a>
            </Container>
        </Layout>
    </div>
)

export default ImpressumPage
